import {Dispatch, SetStateAction, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import usePropSetter from '../../../hooks/usePropSetter';
import BarSpacer from '../../shared/BarSpacer';
import Icon, {IconName} from '../../shared/Icon';
import RadioButton from '../../shared/RadioButton';
import {GlobusImage, useGlobus} from '../GlobusProvider';

import GlobusLayers from './GlobusLayers';
import LayerToggle from './LayerToggle';
import OverlayMapTransparency from './OverlayMapTransparency';

export const PANEL_WIDTH = 300;

type GlobusDrawerProps = {
  setShowLegend: Dispatch<SetStateAction<boolean>>;
};

const GlobusDrawer: React.FC<GlobusDrawerProps> = ({setShowLegend}) => {
  const {t} = useTranslation();
  const {image, overlayMap, setGlobus, bordersEnabled} = useGlobus();
  const handleGlobusImageChange = usePropSetter(setGlobus, 'image');

  const toggleOverlayMapVisible = useCallback(
    () =>
      setGlobus((globus) => {
        if (globus.overlayMap !== null) {
          globus.overlayMap.isVisible = !globus.overlayMap.isVisible;
        }
      }),
    [setGlobus]
  );

  const toggleBordersVisible = useCallback(
    () =>
      setGlobus((globus) => {
        if (globus.bordersEnabled !== null) {
          globus.bordersEnabled = !globus.bordersEnabled;
        }
      }),
    [setGlobus]
  );

  // const toggleTransparent50 = useCallback(
  //   () =>
  //     setGlobus((globus) => {
  //       if (globus.transparent50 !== null) {
  //         globus.transparent50 = !globus.transparent50;
  //       }
  //     }),
  //   [setGlobus]
  // );

  const toggleLegend = useCallback(() => {
    setShowLegend((prev: boolean) => !prev);
  }, [setShowLegend]);

  return (
    <div style={{width: PANEL_WIDTH}} className="h-full bg-diercke-blue-50">
      <DrawerHeadline icon="Globen" label={t('globus:description.globes')} />
      <RadioButton<GlobusImage>
        label={t('globus:description.physicalMap')}
        value="phys"
        checked={image === 'phys'}
        onChange={handleGlobusImageChange}
      >
        <Icon name={image === 'phys' ? 'Radiobutton-02' : 'Radiobutton-01'} className="inline" />
        {t('globus:description.physicalMap')}
      </RadioButton>

      <BarSpacer orientation="y" />

      <RadioButton<GlobusImage>
        label={t('globus:description.satelliteMap')}
        value="sat"
        checked={image === 'sat'}
        onChange={handleGlobusImageChange}
      >
        <Icon name={image === 'sat' ? 'Radiobutton-02' : 'Radiobutton-01'} className="inline" />
        {t('globus:description.satelliteMap')}
      </RadioButton>

      {overlayMap !== null && (
        <>
          <DrawerHeadline icon="Ebenen" label={t('globus:description.layers')} />

          <LayerToggle label="Karte" isEnabled={overlayMap.isVisible} onClick={toggleOverlayMapVisible} />

          <BarSpacer orientation="y" />

          <div className="pl-[40px]">
            <OverlayMapTransparency />
            <BarSpacer orientation="y" />
            <LayerToggle icon="Legende" label={t('globus:description.toggleLegend')} onClick={toggleLegend} />
            <BarSpacer orientation="y" />
          </div>

          <GlobusLayers />
        </>
      )}

      <LayerToggle label="Staatsgrenzen" isEnabled={bordersEnabled} onClick={toggleBordersVisible} />
    </div>
  );
};

export default GlobusDrawer;

type DrawerHeadlineProps = {
  icon: IconName;
  label: string;
};

const DrawerHeadline = ({icon, label}: DrawerHeadlineProps) => {
  return (
    <div className="bg-diercke-blue-250">
      <div className="flex flex-row justify-center">
        <Icon name={icon} />
        <div className="px-1 py-2 font-bold">{label}</div>
      </div>
    </div>
  );
};
