import {useState} from "react";
import {useTranslation} from "react-i18next";

import {useGlobus} from '../GlobusProvider';
import InfoBar from "../../shared/InfoBar";

import DefaultMovementButton, {ICON_BY_DEFAULT_MOVEMENT_MODE} from './DefaultMovementButton';
import HeadToNorthButton from './HeadToNorthButton';
import LookDownwardsButton from './LookDownwardsButton';
import OverlayButton from './OverlayButton';
import OverlayButtonGroup from './OverlayButtonGroup';
import OverlayButtonGroupSpacer from './OverlayButtonGroupSpacer';
import OverlayPopoverButton from './OverlayPopoverButton';
import OverlayPosition from './OverlayPosition';
import TerrainButton from './TerrainButton';
import WestermannLogo, {bottomPaddingWestermannLogo, topWestermannLogo} from './WestermannLogo';
import ZoomInButton from './ZoomInButton';
import ZoomOutButton from './ZoomOutButton';

type GlobusOverlayProps = {
  imageName?: string;
};
const GlobusOverlay: React.FC<GlobusOverlayProps> = ({imageName}) => {
  const {defaultMovementMode, setGlobus, terrainEnabled} = useGlobus();
  const [demScaleValue, setScaleValue] = useState(1.0);

  const formattedNumber = Math.round(+demScaleValue.toFixed(2) * 100) + '';
  const {t} = useTranslation();

  return (
    <div className="relative w-full h-full">
      <div className="absolute left-0 right-0 top-0">
        <OverlayPosition imageName={imageName}/>
      </div>

      <div
        className="absolute right-0 bottom-0 flex flex-col gap-3 items-end mr-5 mb-[10px]"
        style={{bottom: topWestermannLogo + 'px'}}
      >
        <OverlayButtonGroup className="hidden md:flex" orientation="y">
          <ZoomInButton/>
          <OverlayButtonGroupSpacer/>
          <ZoomOutButton/>
          <OverlayButtonGroupSpacer/>
          <OverlayPopoverButton
            icon={defaultMovementMode !== null ? ICON_BY_DEFAULT_MOVEMENT_MODE[defaultMovementMode] : 'Bewegen'}
          >
            <DefaultMovementButton mode="rotate"/>
            <DefaultMovementButton mode="zoom"/>
            <DefaultMovementButton mode="tilt"/>
            <DefaultMovementButton mode="look"/>
          </OverlayPopoverButton>
          <OverlayButtonGroupSpacer/>
          <OverlayPopoverButton
            icon="Legende/Berg"
          >
            <OverlayButton
              grouped={true}
              orientation="y"
              icon="Ueberhoehung_Minus"
              title="Ueberhoehung_Minus"
              onClick={() => {
                demScaleValue > 0.1 && setScaleValue((prev) => prev - 0.5);
                setGlobus(globus => {
                  globus.verticalExaggeration = Math.max(0, globus.verticalExaggeration - 0.5)
                })
              }}
            />
            <OverlayButton
              grouped={true}
              orientation="y"
              icon="Ueberhoehung_Plus"
              iconOverlayPosition="bottom-left"
              title="Ueberhoehung_Plus"
              onClick={() => {
                demScaleValue < 4 && setScaleValue((prev) => prev + 0.5);
                setGlobus(globus => {
                  globus.verticalExaggeration = Math.min(globus.verticalExaggeration + 0.5, 4)
                })
              }}
            />

          </OverlayPopoverButton>

          <OverlayButtonGroupSpacer/>
          <HeadToNorthButton/>
          <OverlayButtonGroupSpacer/>
          <LookDownwardsButton/>
          <OverlayButtonGroupSpacer/>
          <TerrainButton/>
        </OverlayButtonGroup>
      </div>
      <div className="absolute ml-7 right-3/5 bottom-0 bg-white/80 text-black rounded">
        <InfoBar text={t`description.increase` + ` ${ terrainEnabled ? formattedNumber : 0}%`}/>
      </div>
      <div className="flex fixed bg-white  bg-opacity-25 bottom-0 right-0 mr-5" style={{paddingBottom: bottomPaddingWestermannLogo + 'px'}}>
        <p className='text-sm pt-1 pr-3 bg-white text-black bg-opacity-25'>{` (c) 1987-2025 HERE `}</p>
        <WestermannLogo/>
      </div>
    </div>
  );
};

export default GlobusOverlay;
