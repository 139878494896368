import {
  Color,
  createWorldImageryAsync, GridImageryProvider,
  ImageryProvider,
  IonWorldImageryStyle,
  OpenStreetMapImageryProvider,
  UrlTemplateImageryProvider
} from 'cesium';
import {useEffect, useState} from 'react';
import {ImageryLayer} from 'resium';

import unreachable from '../../utils/unreachable';

import {GlobusImage, useGlobus} from './GlobusProvider';

const HERE_API_KEY = 'iGpbhAEqPP5PYILRcynUge_VpaLlNu7SSUAqcCphZoA';

type Props = {image: GlobusImage};

async function getImageryProvider(image: GlobusImage): Promise<ImageryProvider> {

  switch (image) {
    case 'sat':
      // Provide a custom URL to support Here Maps.
      // https://cesium.com/learn/cesiumjs/ref-doc/UrlTemplateImageryProvider.html
      // https://developer.here.com/documentation/map-tile/dev_guide/topics/example-satellite-map.html
      // https://2.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/satellite.day/5/15/12/256/png8?apiKey={YOUR_API_KEY}
      // 'https://2.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/jpg?apiKey=' +
      // 'https://maps.hereapi.com/v3/raster/tiles/{z}/{x}/{y}/satellite?apikey=' + HERE_API_KEY

      return new UrlTemplateImageryProvider({
        url: 'https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/jpeg?style=satellite.day&apiKey='+ HERE_API_KEY,
        credit: '© Here Maps',
      });

    case 'satlabels':
      return await createWorldImageryAsync({
        style: IonWorldImageryStyle.AERIAL_WITH_LABELS,
      });

    case 'phys':
      return new OpenStreetMapImageryProvider({
        url: 'https://globusserver.de/cgi-bin/ImagonTileCache2.pl?mergephysical/',
        maximumLevel: 12,
        fileExtension: 'jpg',
      });

    case 'osm':
      return new OpenStreetMapImageryProvider({
        url: 'https://b.tile.openstreetmap.org/',
      });

    case 'dierckeRoads':
      return new OpenStreetMapImageryProvider({
        url: 'https://globusserver.de/cgi-bin/ImagonTileCache2.pl?osmroads/',
      });

    case 'dierckeBorders':
      return new OpenStreetMapImageryProvider({
        url: 'https://globusserver.de/cgi-bin/ImagonTileCache2.pl?osmborders/',
      });

    case 'grid':
      return new GridImageryProvider({
        tileWidth: 2048,
        tileHeight: 2048,
        color: new Color(0.0, 1.0, 1.0, 0.1),
      });

    default:
      unreachable(image);
  }
}


export const TileImageryProvider: React.FC<Props> = ({image}) => {
  const {image: selectedImage, bordersEnabled} = useGlobus();
  const [imageryProvider, setImageryProvider] = useState<ImageryProvider | null>(null);
  const [imageryProviderBorders, setImageryProviderBorders] = useState<ImageryProvider | null>(null);

  useEffect(() => {
    let mounted = true;
    const fetchImageryProviders = async () => {
      const provider = await getImageryProvider(image);  // Fetch the imagery for the selected image
      const providerBorder = await getImageryProvider('dierckeBorders');  // Fetch borders imagery

      if (mounted) {
        setImageryProvider(provider);
        setImageryProviderBorders(providerBorder);
      }
    };

    fetchImageryProviders().catch(console.error);
    return () => {
      mounted = false;
    };
  }, [image]); // Re-run the effect when the image changes

  if (!imageryProvider || !imageryProviderBorders) {
    return null; // Return null if no imagery provider is set yet
  }

  return (
    <>
      <ImageryLayer imageryProvider={imageryProvider} show={selectedImage === image} />
      {bordersEnabled && (
        <ImageryLayer imageryProvider={imageryProviderBorders} show={true} />
      )}
    </>
  );
};
